
import React from 'react'
import styled from "@emotion/styled"
import { theme } from '../../config/theme';

const StyledSlide = styled.div`
    display:flex;
    width: 100%;
    padding: 10px 0 90px;
    justify-content:center;
    align-items:center;
    position:relative;
    overflow:hidden;
    @media (max-width: ${theme.breakpoints.l}) {
        padding: 10px 0 45px;
        }
    h3{
        color: black;
        font-size:3.3em;
        font-weight: 900;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.1em;
        margin-bottom: 25px;
        white-space:pre-line;
        @media (max-width: ${theme.breakpoints.l}) {
            font-size:1.7em;
        }
    }
    .slide__content {
        max-width:560px;
        text-align:center;
    }
    .slide__content--extra {
        font-size: 1.4em;
        margin-top: 65px;
        strong {font-weight:800;}
        @media (max-width: ${theme.breakpoints.l}) {
            font-size: 1em;
        }
    }
`

const Slide = ({title,children}) => {
    return ( <StyledSlide className="keen-slider__slide">
        <div className="slide__content">
            <h3>{title}</h3>
            {children}
        </div>
        
    </StyledSlide> );
}
 
export default Slide;