import React, { useEffect, useState } from 'react';
import { useKeenSlider } from "keen-slider/react";
//import "keen-slider/keen-slider.min.css";
import Card from '../card/card';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import styled from '@emotion/styled';
import { stagger, fadeIn } from '../../config/motions';
import Slide from './slide';
import torUrl from '../icons/tor.svg'
import torShadowUrl from '../icons/tor_shadow.svg'
import tabletUrl from '../icons/tablet.svg'
import beratungUrl from '../icons/beratung.svg'
import beratungShadowUrl from '../icons/beratung_shadow.svg'
import tabletShadowUrl from '../icons/tablet_shadow.svg'
import FloatingElement from '../elements/floatingElement';
import { theme } from '../../config/theme';
import SliderArrows from './sliderControls';
import { RelatedLink } from '../elements/relatedLinks';

const FloatingItemOne = styled(motion.div)`
  position:absolute;
  top: 16%;
  left: 7%;
  width: 170px;
  @media (max-width: ${theme.breakpoints.l}) {
      display: none;
    }
`
const FloatingItemThree = styled(motion.div)`
  position:absolute;
  top: 8%;
  right: -158%;
  width: 190px;
  @media (max-width: ${theme.breakpoints.l}) {
      display: none;
    }
`
const FloatingItemTwo = styled(motion.div)`
  position:absolute;
  bottom: 20px;
  right: -40px;
  width: 200px;
  @media (max-width: ${theme.breakpoints.l}) {
    display: none;
    }
`

const StyledSlider = styled(motion.div)`
    overflow: hidden;
    position: relative;
  

   /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;*/

    //background:linear-gradient(#fff, #CAF5FF);
    //background:linear-gradient(#fff, #CAF5FF);
    background: linear-gradient(to bottom, #fcfcfc, #EFEFEF);


.slide__content {
  width: 95%;
}
`

const StyledSliderHolder = styled.div`
    position: relative;
    

    &:before {
      content:'';
      left:-60px;
      height: 100%;
      position:absolute;
      top: 0;
      width: calc(100% + 120px);
    }
    &:after {
      content:'';
      left:-35vw;
      height: 100%;
      position:absolute;
      z-index:1;
      top: 0;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 83%, rgba(255,255,255,0) 100%);
      width: 35vw;
    }
   
    img {
    width:100%;
    display:block;
  }
  
  
`
const HeroSliderHeader = styled.div`

position:relative;
    display: flex;
  flex-wrap: wrap;
  flex-direction:row;
  padding-bottom: 10px;
  align-items: baseline;
    h2{
      width: 170px;
    }
    div {
      font-size: 1.1em;
      line-height: 1.15em;
    }
    .dots {
      display:none;
      @media (max-width: ${theme.breakpoints.l}) {
      display: block;
    }
  }
  @media (max-width: ${theme.breakpoints.l}) {
      display: block;
    }
   
.heroSlider__nav {

  @media (max-width: ${theme.breakpoints.l}) {
    position: absolute;
    top: 3px;
    left: 130px;
    text-align:left;
      > div {display: none;
        
      &.selected {
        display:block;
        color: ${theme.colors.text} !important;
        &:after{
          display:none;
        }
      }}
    }
    display: ${props => props.showNavInfoOnMobile ? 'none !important' : 'flex'};
  flex-wrap: wrap;
  flex-direction:row;
  margin-left: 24px;
  margin-bottom: 24px;
    align-items:center;
    
    > div {
      width: 170px;
      cursor: pointer;
      white-space: pre-line;
      transition: .38s all cubic-bezier(0.075, 0.82, 0.165, 1);
      position:relative;
      margin-right: 24px;
      &.selected{
        color: ${theme.colors.primary};
        &:after{
          width: 100% !important;
          background-color: ${theme.colors.primary};
        }
      }
      &:after{
        content:'';
        transition: .38s all cubic-bezier(0.075, 0.82, 0.165, 1);

        position:absolute;
        bottom: -10px;
        left: 0;
        width: 20px;
        height: 2px;
        background-color: #ddd;
      }
      &:hover:after {
        width: 40px;
      }
    }
  }
`

const HeroNav = ({ slider, currentSlide, items, title, ...props }) => {
  return (
    <HeroSliderHeader {...props} className="heroSlider__header">
      <h2 className={props.hideTitle ? 'no-border' : ''}>{title.split('\n')}</h2>
      {slider &&
        <div className="dots">
          {[...Array(slider.details().size).keys()].map(idx => {
            return (
              <button
                name="button-dot"
                key={`button-heroSlider-${idx}`}
                aria-label="Slider Dot-Button"
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            );
          })}
        </div>
      }
      <div className="heroSlider__nav">
        {
          items && items.map((item, index) =>
            <div key={`slider-${item.id}`} className={`${index === currentSlide ? 'selected' : ''} link`} onClick={() => slider.moveToSlideRelative(index)} key={`navItem-${index}`}>{item.title.replace('Digitaler Content', 'Digitaler\n Content')}</div>
          )
        }
      </div>
    </HeroSliderHeader>
  )
}

const HeroSlider = ({ items, onChange, navPosition = "top", ...props }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [position, setPosition] = useState(0);
  const x1 = useMotionValue(0);
  const x2 = useMotionValue(0);
  const x3 = useMotionValue(0);//useTransform(currentSlide, [0, 400], [0, -100]);
  /*useEffect(() => {
    effect
  }, [currentSlide])*/

  const [sliderRef, slider] = useKeenSlider({
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
      //console.log(s.details().absoluteSlide);
      onChange && setTimeout(() => onChange(items[s.details().absoluteSlide])
        , 100)
    },
    move: (s) => {
      x1.set((s.details().position * -1) / 4)
      x2.set((s.details().position * -1) / 1.2)
      x3.set((s.details().position * -1) / 1.2)
    }
  });

  return (<div style={{ position: 'relative' }}>
    {navPosition === 'top' && 
    <HeroNav showNavInfoOnMobile={!props.showLink} title={'Leistungen'} items={items} currentSlide={currentSlide} slider={slider} />}
    <StyledSliderHolder className="slider__holder">
      <StyledSlider initial='initial' animate='animate' variants={stagger} ref={sliderRef} className="keen-slider">
        {
          items && items.map((item, index) =>
            <Slide key={`slider-${index}`} title={item.title}>
              <div>
                {
                  item.description && <div className="paragraph"
                    dangerouslySetInnerHTML={{
                      __html: item.description.childMarkdownRemark.html,
                    }}
                  />
                }
                {
                  item.artikel &&
                  <div className="slide__content--extra">
                    {
                      item.artikel.filter(it => it.inGrauerBoxAnzeigen).slice(0, 5).map(artikel => <div key={`heroSlider-Artikel_${artikel.slug}`}>
                        <RelatedLink data={artikel} modalType={"post"} />

                        {1 > 2 && artikel.kunde && <RelatedLink data={artikel.kunde} modalType={"client"} />}
                        {artikel.kunde && ` ${artikel.kunde.name}`}
                      </div>)
                    }
                  </div>
                }
              </div>
            </Slide>
          )
        }

      </StyledSlider>
      <FloatingItemOne style={{ x: x1 }}>
        <FloatingElement shadowDistance={20} shadow={<img src={torShadowUrl} alt="Tor" />} elem={<img src={torUrl} alt="Tor" />} />

      </FloatingItemOne>
      <FloatingItemTwo style={{ x: x2 }}>
        <FloatingElement shadowDistance={15} shadow={<img src={tabletShadowUrl} alt="Tablet" />} elem={<img src={tabletUrl} alt="Tor" />} />
      </FloatingItemTwo>
      <FloatingItemThree style={{ x: x3 }}>
        <FloatingElement shadowDistance={-100} shadow={<img src={beratungShadowUrl} alt="Beratung Shatten" />} elem={<img src={beratungUrl} alt="Icon Beratung" />} />
      </FloatingItemThree>
      <>
        {slider && <SliderArrows slider={slider} currentSlide={currentSlide} />}

      </>


    </StyledSliderHolder>
    {navPosition === 'bottom' && <HeroNav style={{ marginTop: 40 }} {...props} title={'Art der\n Leistung'} items={items} currentSlide={currentSlide} slider={slider} />}
  </div>
  );
}




export default HeroSlider;