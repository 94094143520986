import { motion } from 'framer-motion';
import React from 'react';

/*const float = (duration) => ({
    animate: {
      translateY: 30,
      //scale: 1-(duration/80),
      transition: { yoyo: Infinity, ease: "easeInOut",duration: duration },
    }
  })
  const shadowMotion = (duration) => ({
    animate: {
        scale: 1-(duration/80),
      transition: { yoyo: Infinity, ease: "easeInOut",duration: duration },
    }
  })*/
  const float = (duration) => ({
    translateY: 30,
    transition: { yoyo: Infinity, ease: "easeInOut",duration: duration },
  })
  const shadowMotion = (duration) => ({
    scale: .7,
    transition: { yoyo: Infinity,ease: "easeInOut",duration: duration },
  })

const FloatingElement = ({elem,shadow,shadowDistance}) => {
    const duration = (Math.floor(Math.random()*10 +2));
    return (  <div style={{userSelect:'none'}}><motion.div 
        //variants={floatMotion(Math.floor(Math.random()*10 +2))}  
        animate={float(duration)}
    >
        {elem}
      </motion.div> 
      { 
          shadow && <motion.div 
          style={{marginTop:shadowDistance}}
          animate={shadowMotion(duration)}
      >
          {shadow}
        </motion.div> 
      }
      </div>
);
}
 
export default FloatingElement;