import React from "react"
import HeroSlider from '../carousel/heroSlider';
import { useStaticQuery,Link } from 'gatsby';

const Services = ({showLink,...props}) => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulLeistung (sort: {order: ASC, fields: createdAt}){
            edges {
                node {
                id,
                slug,
                title,
                description {
                  childMarkdownRemark {
                    html,
                    excerpt
                  }
                },
                artikel {
                  title
                  slug
                  publishDate(formatString: "MMMM Do, YYYY")
                  tags
                  inGrauerBoxAnzeigen
                  leistung
                  template:produkt
                  image:heroImage {
                    fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  description {
                    childMarkdownRemark {
                      html
                      excerpt
                    }
                  }
                  body {
                    childMarkdownRemark {
                      html
                    }
                  }
                  kunde {
                    name
                    url
                    body {
                      childMarkdownRemark {
                        html
                      }
                    }
                    logo {
                      fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                        ...GatsbyContentfulFluid_withWebp
                      }
                      file {
                        fileName
                        url
                        details {
                          image {
                            height
                            width
                          }
                        }
                      }
                    }
                    artikel {
                      title
                      slug
                      publishDate(formatString: "MMMM Do, YYYY")
                      tags
                      leistung
                      template:produkt
                      kunde {
                        name
                        id
                      }
                      image:heroImage {
                        fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                          ...GatsbyContentfulFluid_withWebp
                        }
                      }
                      description {
                        childMarkdownRemark {
                          html,
                          excerpt
                        }
                      }
                      body {
                        childMarkdownRemark {
                          html
                        }
                      }
                    }
                  }
                }
            }
        }
    }
  }
  `)

  
  const Leistungen = data.allContentfulLeistung.edges.map(it=>it.node);
  
 /*const Slides = [
    {title:`Corporate\n Publishing`},
    {title:'Digitaler\n Content'},
    {title:'Kommunikationsstrategien\n & Medienarbeit'}
  ];*/
    return ( <>
   {!props.hideTitle &&  <h2>Services</h2>}
   
      <HeroSlider {...props} items={Leistungen} />
      {showLink && <Link className="show-all" to="/kunden-leistungen">Alle Leistungen ansehen</Link>} 

        </> );
}
 
export default Services;